export const initialState = {
  activeCourse: null,
  headerView: null,
  courseAssessmentFetching: true,
  courseAssessment: null,
  usersList: [],
  currentUser: null,
  currentUserResponse: [],
  userResponseLoading: true,
  allQuestionsLoading: true,
  assessmentAllQuestions: [],
  currentResourceKey: null,
  resourceid: null,
  resourceType: null,
  assessmentResourceDropdown: [],
};
