export const reducers = {
    setFavArrRedux(state, action) {
        state.favArrRedux = action.payload
    },
    setStar(state, action) {
        state.star = action.payload
    },
    setStarFill(state, action) {
        state.starFill = action.payload
    }
};

