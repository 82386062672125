export const reducers = {

  setActiveBold(state) {
    state.certificateHeaderActive.bold = !state.certificateHeaderActive.bold;
  },
  setActiveItalic(state) {
    state.certificateHeaderActive.italic =
      !state.certificateHeaderActive.italic;
  },
  setActiveUnderline(state) {
    state.certificateHeaderActive.underline =
      !state.certificateHeaderActive.underline;
  },
  setActiveFontFamily(state, action) {
    state.certificateHeaderActive.fontFamily = action.payload;
  },
  setActiveFontSize(state, action) {
    state.certificateHeaderActive.fontSize = action.payload;
  },
  setActiveAlign(state, action) {
    state.certificateHeaderActive.align = action.payload;
  },
  setActiveHeader(state, action) {
    state.certificateHeaderActive = action.payload;
  },
  setCertificates(state, action) {
    state.certificates = action.payload;
  },
  setCertificateFetching(state, action) {
    state.certificateFetching = action.payload;
  },
  setSearch(state,action){
    state.search=action.payload;
  },
  setRoleData(state, action){
    state.roleData=action.payload;
  },
    SetIsOnlineGoggleMeet(state,action){
      state.isOnlineGoggleMeet=action.payload;
    },


    //EMAIL TEMPLATES setstates
    setSelectedCategory(state, action){
      state.selectedCategory=action.payload;

    },

    setTemplates(state, action){
      state.templates=action.payload;

    },

    setSaveTemplate(state, action){
      state.saveTemplate=action.payload;

    },

    setAddtemplate(state,action){
      state.addTemplate=action.payload;

    },

    setViewTemplate(state, action) {
      state.viewTemplate = action.payload;
    },
  
    setActiveTemplate(state, action) {
      state.activeTemplate = action.payload;
    },

    setDefaultTemplate(state, action){
      state.defaultTemplate=action.payload;
    },
  
    setEditFlag(state, action) {
      state.editFlag = action.payload;
    },
  
    setTemplateName(state, action) {
      state.templateName = action.payload;
    },
  
    setTemplateSubject(state, action) {
      state.templateSubject = action.payload;
    },
  
    setSelectedOption(state, action) {
      state.selectedOption = action.payload;
    },
  
    setEditIndex(state, action) {
      state.editIndex = action.payload;
    },
  
    setEditTemplate(state, action) {
      state.editTemplate = action.payload;
    },
  
    setAddNewTempModal(state, action) {
      state.AddNewTempModal = action.payload;
    },
  
    setCreateTemplateScreen(state, action) {
      state.CreateTemplateScreen = action.payload;
    },
   
    setActiveTemplate(state, action) {
      state.activeTemplate = action.payload;
    },

    setChangeDefault(state, action){
      state.ChangeDefault = action.payload;

    }
  
    // editTemplate(state, action) {
    //   const { index, editedTemplate } = action.payload;
    //   state.EmailTemplate.templates[index] = editedTemplate;
    // },

  
};


