import { initialState } from "./coursePlayerInitialState";

export const reducers = {
  setCoursePlayerModal(state, action) {
    // type:course/quizResult, status: true/false
    state.coursePlayerModal = action.payload;
  },
  setActivePanel(state, action) {
    state.activePanel = action.payload;
  },
  setActiveLesson(state, action) {
    state.activeLesson = action.payload;
  },
  setActiveAsset(state, action) {
    state.activeAsset = action.payload;
  },
  setCourseInfo(state, action) {
    state.courseInfo = action.payload;
  },
  setCourseResult(state, action) {
    state.courseResult = action.payload;
  },
  setLessonInfo(state, action) {
    state.lessonInfo = action.payload;
  },
  setCourseInfoFetching(state, action) {
    state.courseInfoFetching = action.payload;
  },
  setCoursePlayerAllAssets(state, action) {
    state.allAssets = action.payload;
  },
  setCoursePlayerForceSequence(state, action) {
    state.forceSequence = state.forceSequence.concat(action.payload);
  },
  setCoursePlayerCompletedAssets(state, action) {
    state.completedAssets = state.completedAssets.concat(action.payload);
  },
  setActiveView(state, action) {
    state.activeView = action.payload;
  },
  setLessonsObject(state, action) {
    state.lessonsObject = action.payload;
  },
  setCourseCompletion(state, action) {
    state.courseCompletionAllowed = action.payload;
  },
  setCourseSubmitted(state, action) {
    state.courseSubmitted = action.payload;
  },
  setCourseFeedbackPopup(state, action) {
    state.courseFeedbackPopup = action.payload;
  },
  setIsCourseSubmiting(state, action) {
    state.isCourseSubmiting = action.payload;
  },
  setCourseProgress(state, action) {
    state.courseProgress = action.payload;
  },
  setcachedAssetDetails(state, action) {
    state.cachedAssetDetails = {
      ...state.cachedAssetDetails,
      ...action.payload,
    };
  },
  setHasDescriptiveQuestion(state, action) {
    state.hasDescriptiveQuestion = action.payload;
  },

  resetState(state) {
    Object.assign(state, initialState);
  },
};
