import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { message } from "antd";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.scss";
import "./i18n.js";
import "./global.scss";

import store from "./store/index.js";

//components
import App from "./components/authorized/App";
import { Suspense } from "react";
import { registerServiceWorker } from "../src/components/authorized/firebase";

message.config({ maxCount: 1 });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <React.Suspense fallback="Loading...">
          <App />
        </React.Suspense>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

registerServiceWorker();
