import { notification } from "antd";
import axios from "axios";
import { GATEWAY_URL } from "../../utils/contants";
import { authActions } from "./index";
import moment from "moment";
import { updateStreak } from "../../components/authorized/firebase";
import MessageComponent from "../../components/MessageComponent";

const openNotification = (type = "", message = "", description = "") => {
  notification[type]({
    message,
    description,
  });
};

const handleThemeChange = (theme) => {
  console.log("val = ", theme);

  document.documentElement.style.setProperty("--primary-bg", theme?.pbg);
  document.documentElement.style.setProperty("--primary-text", theme?.pcolor);
  document.documentElement.style.setProperty("--secondary-bg", theme?.sbg);
  document.documentElement.style.setProperty("--secondary-text", theme?.scolor);
  document.documentElement.style.setProperty("--text-color", theme?.textColor);
  // document.documentElement.style.setProperty("--backdrop-color", theme?.bColor);
  document.documentElement.style.setProperty("--heading-color", theme?.bColor);

  document.documentElement.style.setProperty(
    "--accent-color",
    theme?.accentColor
  );
  document.documentElement.style.setProperty(
    "--primary-filter",
    theme?.pfilter
  );
  document.documentElement.style.setProperty(
    "--secondary-filter",
    theme?.sfilter
  );
  document.documentElement.style.setProperty("--text-filter", theme?.tfilter);
  document.documentElement.style.setProperty("--accent-filter", theme?.afilter);
  console.log(theme?.textColor == "#2a7086" ? "#e1f1f5" : theme?.textColor);
  document.documentElement.style.setProperty(
    "--text-light",
    theme?.accentColor == "#1e5d71" ? "#e1f1f5" : theme?.accentColor
  );
};
export const LoginUser = (userData, navigate) => {
  return async (dispatch) => {
    try {
      // MessageComponent({ type: "loading" });
      if (!navigator.onLine) {
        openNotification("error", "Please check your Internet Connection");
        return;
      }
      dispatch(authActions.setLoginButtonDisable(true));

      const { data } = await axios.post(
        `${GATEWAY_URL}/login`,
        {
          username: userData.username,
          password: userData.password,
          loginTime: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          withCredentials: true,
        }
      );
      console.log(data);
      handleThemeChange(JSON.parse(data?.user?.theme?.data));
      dispatch(
        authActions.loginUser({
          accessToken: data.accessToken,
          isLoggedIn: true,
          user: { ...data.user, permissions: data.user.permissions?.id },
          permissions: data.user.permissions,
          orgId: data.user.org_id,
        })
      );
      //Streak functionality
      const dataStreak = await updateStreak(data.user.id, data.user.org_id);
      dispatch(authActions.setcurrentStreak(dataStreak.current_streak));
      dispatch(authActions.setStreakDays(dataStreak.streak_days));
      // console.log(dataStreak, "data11");

      // Fetch followed users count
      // dispatch(fetchFollowedUsersCount(data.user.id));

      if (dataStreak.current_streak === 0 && dataStreak.last_streak > 0) {
        dispatch(authActions.setBrokenStreakModalOpen(true)); // Break streak
      } else {
        dispatch(authActions.setStreakModalOpen(true));
      }
      // navigate("/feed", { replace: true });
      // navigate("/courses", { replace: true });
      navigate("/main-dashboard", { replace: true });
    } catch (error) {
      console.log(error);
      openNotification("error", "Login Failure", error.response.data?.message);
    } finally {
      MessageComponent({ type: "destroy" });
      dispatch(authActions.setLoginButtonDisable(false));
    }
  };
};
export const getAccessToken = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${GATEWAY_URL}/refresh`, {
        withCredentials: true,
      });
      handleThemeChange(JSON.parse(data?.user?.theme?.data));
      dispatch(
        authActions.loginUser({
          accessToken: data.accessToken,
          isLoggedIn: true,
          user: { ...data.user, permissions: null },
          permissions: data.user.permissions,
          orgId: data.user.org_id,
        })
      );
      //Streak functionality
      const dataStreak = await updateStreak(data.user.id, data.user.org_id);
      dispatch(authActions.setcurrentStreak(dataStreak.current_streak));
      dispatch(authActions.setStreakDays(dataStreak.streak_days));

      // Fetch followed users count

      // Fetch followed users count
      // dispatch(fetchFollowedUsersCount(data.user.id));
    } catch (error) {
      console.log(error);
      if (error.response.data !== "unauthorised") {
        openNotification("error", "Please login again", error.response.data);
      }
    } finally {
      dispatch(authActions.checkingUserToken(false));
    }
  };
};

export const logoutUser = (navigate) => {
  if (window.performance && window.performance.clearResourceTimings) {
    window.performance.clearResourceTimings();
  }
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.indexedDB.deleteDatabase("");

  return async (dispatch) => {
    dispatch(authActions.setLoginButtonDisable(true));
    const fetchData = async () => {
      await axios
        .get(`${GATEWAY_URL}/logout`, {
          withCredentials: true,
        })
        .then(() => {
          //TODO: May need to improve this logic for better performance
          if (window.performance && window.performance.clearResourceTimings) {
            window.performance.clearResourceTimings();
          }
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.indexedDB.deleteDatabase("");
        });
    };

    try {
      await fetchData();
      window.open("/login", "_self", false);
      // window.location.hash = "no-back-button";

      // Again because Google Chrome doesn't insert
      // the first hash into the history
      // window.location.hash = "Again-No-back-button";

      // window.onhashchange = function () {
      //   window.location.hash = "no-back-button";
      // };
      // navigate("/login", { replace: true });

      // dispatch(
      //   authActions.loginUser({
      //     accessToken: "",
      //     isLoggedIn: false,
      //   })
      // );
      // navigate("/login");
      // openNotification("success", "User Loggedout Sucessfully");
    } catch (error) {
      openNotification("error", "Logout Failure", error.response.data.message);
    } finally {
      dispatch(authActions.setLoginButtonDisable(false));
    }
  };
};

// Action Type
export const SET_FOLLOWED_USERS_COUNT = "SET_FOLLOWED_USERS_COUNT";

// Action Creator
export const setFollowedUsersCount = (count) => ({
  type: SET_FOLLOWED_USERS_COUNT,
  payload: count,
});

// Action Type
export const UPDATE_FOLLOWING_STATUS = "UPDATE_FOLLOWING_STATUS";

// Action Creator
export const updateFollowingStatus = (userId, isFollowing) => ({
  type: UPDATE_FOLLOWING_STATUS,
  payload: { userId, isFollowing },
});
