import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducer";
import { initialState } from "./initialState";

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers,
});

export const quizActions = quizSlice.actions;
export default quizSlice.reducer;
