export const initialState = {
  publishedLearningPath: [],
  draftLearningPath: [],
  assignedLearningPath: [],
  myCreationLearningPath: [],
  ongoingLearningPath: [],
  isLoading: true,
  isAssignedLearningPathLoading: true,
  // pagination: {},
  showAddLearningPathModal: false,
  selectedLearningPath: {},
  selectedLearningPathId: null,
  // selectedLearningPathView: [],
  showLearningPathAgendaModal: false,
  showDeleteLearningPathModal: false,
  showAssignLearningPathModal: false,
  showRenameLearningPathModal: false,
  currentTab: "active",
};
