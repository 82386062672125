import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducer";

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers,
});



export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
