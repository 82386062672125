import { quizConstants } from "../../utils/contants";

export const initialState = {
  started: false,
  questions: [],
  activeQuestionNumber: 0,
  quizResponse: [],
  score: 0,
  started_at: "",
  take_id: "",
  activeView: quizConstants.VIEW_INSTRUCTION,
  timer: undefined,
  result: {},
  quizInfo: {},
  disableSubmitButton: false,
  quizTakeInfoLoading: false,
  startQuizLoading: false,
  finishQuizLoading: false,
  quizPlayerModal: false,
  // THIS quizAssignModalVisible STATE IS USED FOR SHOWING MODAL IN POPUP
  quizAssignModalVisible: false,
  quizId: null,
  quizReport_Modal: false,
  quizName:"",
  manualReloading:true, // will help in reloading instruction button whenever user try again to take quiz again

  ChatQuizBox:false, 
};
