import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducer";

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers,
});

export const assessmentActions = assessmentSlice.actions;

export default assessmentSlice.reducer;
