export const reducers = {
  setFeedbackModalVisible(state, action) {
    state.feedback = action.payload;
  },
  setPublishedFeedback(state, action) {
    state.publishedFeedback = action.payload;
  },
  setActiveFeedback(state, action) {
    state.activeFeedback = action.payload;
  },
  setFeedbackId(state, action) {
    state.feedbackId = action.payload;
  },
  setUserName: (state, action) => {
    state.userName = action.payload;
  },
  setCourseName: (state, action) => {
    state.courseName = action.payload;
  },
  setLearningPathName: (state, action) => {
    state.learningPathName = action.payload;
  },
  setCurrentFeedbackType(state, action) { 
    state.currentFeedbackType = action.payload;
  },
  // setActiveUser(state, action) {
  //   state;
  // },

  // setAssetSearchQuery(state, action) {
  //   state.search = action.payload;
  // },
  // setAssetSortType(state, action) {
  //   state.sortType = action.payload;
  // },
  // setAssetSortOrder(state, action) {
  //   state.sortOrder = action.payload;
  // },
  // setPublishedAssets(state, action) {
  //   state.publishedAssets = action.payload;
  // },
  // setDraftAssets(state, action) {
  //   state.draftAssets = action.payload;
  // },
  // setAssetTypes(state, action) {
  //   state.assetTypes = action.payload;
  // },
  // setUploadedDate(state, action) {
  //   state.uploadDate = action.payload;
  // },
  // setCustomDate(state, action) {
  //   state.customDate = action.payload;
  // },
  // setResetFilters(state) {
  //   state.assetTypes = [];
  //   state.uploadDate = "all";
  //   state.customDate = [];
  // },
  // setResetAssets(state) {
  //   state.draftAssets = [];
  //   state.publishedAssets = [];
  //   state.loading = true;
  // },
  // setCurrentTab(state, action) {
  //   state.currentTab = action.payload;
  // },
};
