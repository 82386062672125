export const initialState = {
  certificateHeaderActive: {
    bold: false,
    underline: false,
    italic: false,
    align: "center",
    fontFamily: undefined,
    fontSize: 16,
  },
  // email template 
  //  EmailTemplate :{
    selectedCategory: 'Course',
    addTemplate: false,
    viewTemplate: false,
    activeTemplate: null,
    editFlag: false,
    defaultTemplate:false,
    templateName: '',
    templateSubject: '',
    selectedOption: 'Select Category',
    editIndex: null,
    editTemplate: null,
    AddNewTempModal: false,
    ChangeDefault:true,
    CreateTemplateScreen: false,

  // },
  templates: [],
  saveTemplate: false,


  isOnlineGoggleMeet : false,
  certificates: [],
  certificateFetching: false,
  search:"",
  rolesData: []
};
