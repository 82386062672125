import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./coursePlayerInitialState";
import { reducers } from "./coursePlayerReducers";

const coursePlayerSlice = createSlice({
  name: "coursePlayer",
  initialState,
  reducers,
});

export const coursePlayerActions = coursePlayerSlice.actions;

export default coursePlayerSlice.reducer;
