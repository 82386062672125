import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./assetInitialState";
import { reducers } from "./assetsReducer";

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers,
});

export const assetsActions = assetsSlice.actions;

export default assetsSlice.reducer;
