import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import moment from "moment";
import {
  getDatabase,
  ref,
  push,
  set,
  get,
  startAt,
  endAt,
  update,
  onValue,
  limitToLast,
  limitToFirst,
  serverTimestamp,
  remove,
  orderByChild,
  onChildAdded,
  query,
} from "firebase/database";
import {
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBEaucHWT9aKrDH5i8Pfiy1gwAHB0ZxuF0",
  authDomain: "kognics-app-resources.firebaseapp.com",
  databaseURL: "https://kognics-app-resources-default-rtdb.firebaseio.com",
  projectId: "kognics-app-resources",
  storageBucket: "kognics-app-resources.appspot.com",
  messagingSenderId: "644573997429",
  appId: "1:644573997429:web:399558232bc4462f0a8b3c",
  measurementId: "G-J62QLVWBXY",
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      // if (registration.installing) {
      // } else if (registration.waiting) {
      // } else if (registration.active) {
      // }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

//initialize db
export const db = getDatabase(app);

//*********** GROUP CHAT**************** */
export const fetchGroupChat = async (
  groupId,
  org_id,
  setMessages,
  setLoadingChat
) => {
  setLoadingChat(true);
  try {
    // Create a query to fetch all messages ordered by timestamp
    const messagesRef = ref(db, `${org_id}/groupschat/${groupId}`); // Assuming your messages are under the "users" node
    const messagesQuery = query(messagesRef, orderByChild("timestamp"));

    const snapshot = await get(messagesQuery);

    if (snapshot.exists()) {
      const allMessages = [];
      snapshot.forEach((userSnapshot) => {
        const userId = userSnapshot.key;

        const userMessages = userSnapshot.val().messages || {};
        Object.keys(userMessages).forEach((messageKey) => {
          const message = userMessages[messageKey];
          allMessages.push({ messageId: messageKey, userId, ...message });
        });
      });
      // Sort all messages by timestamp (oldest to newest)
      allMessages.sort((a, b) => a.timestamp - b.timestamp);
      setMessages(allMessages);
    } else {
      setMessages([]);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoadingChat(false);
  }
};

export const writeGroupChat = (
  groupId,
  org_id,
  setInput,
  setFiles,
  input,
  userId,
  username,
  profilePicture,
  file
) => {
  const newFileList = file?.map((file) => ({
    name: file.name,
    url: file.key,
    type: file.type,
  }));
  const userMessagesRef = ref(
    db,
    `${org_id}/groupschat/${groupId}/${userId}/messages`
  );
  const newMessageRef = push(userMessagesRef);

  set(newMessageRef, {
    userid: userId,
    username: username,
    text: input.message,
    documents: newFileList,
    profile_pic: profilePicture,
    timestamp: serverTimestamp(),
  });

  const chatRoomRef = ref(db, `${org_id}/groupschat/${groupId}`);
  update(chatRoomRef, {
    senderID: userId,
    username: username,
    lastMessage: input.message,
    timestamp: serverTimestamp(),
  });
  setInput({
    message: "",
    username: username,
  });
  setFiles([]);
};

export const listenForNewMessagesGroup = (
  setMessages,
  org_id,
  userid,
  groupid
) => {
  const messagesRef = ref(db, `${org_id}/groupschat/${groupid}/`); // Listen to the "users" node to get updates for all users

  // Use the onChildAdded method to listen for new messages
  onChildAdded(messagesRef, (userSnapshot) => {
    const userid = userSnapshot.key;
    const userMessagesRef = ref(
      db,
      `${org_id}/groupschat/${groupid}/${userid}/messages`
    );

    // Now, listen to the "messages" node for this user to get updates for their messages
    onChildAdded(userMessagesRef, (messageSnapshot) => {
      const newMessage = messageSnapshot.val();
      // Handle the new message (e.g., add it to your messages state)
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
  });
};

export const extractAllGroups = async (org_id, setAllGroupsDetails, userid) => {
  const chatRoomsRef = ref(db, `${org_id}/groupschat/`);

  // Listen for changes to the chat_rooms reference
  onValue(chatRoomsRef, (snapshot) => {
    if (snapshot.exists()) {
      const chatRoomsData = snapshot.val();
      const userChatRooms = [];

      for (const groupId in chatRoomsData) {
        if (chatRoomsData.hasOwnProperty(groupId)) {
          const groupData = chatRoomsData[groupId];

          // Get the last message from the group
          const lastMessage = groupData.lastMessage;

          // Get the user IDs inside the group
          const userIDs = Object.keys(groupData)
            .filter(
              (key) =>
                key !== "lastMessage" &&
                key !== "senderID" &&
                key !== "username"
            )
            .map((id) => parseInt(id, 10));

          const groupInfo = {
            groupId: parseInt(groupId, 10),
            lastMessage: groupData.lastMessage,
            senderID: groupData.senderID,
            username: groupData.username,
            userIDs: userIDs,
            timestamp: groupData.timestamp,
          };

          userChatRooms.push(groupInfo);
          userChatRooms.sort((a, b) => b.timestamp - a.timestamp);
        }
      }
      setAllGroupsDetails(userChatRooms);
    } else {
      console.log("No chat rooms found.");
    }
  });
};

//*********** P2P CHAT **************** */
export const writeUserDataUsers = (
  file,
  senderId,
  recieverId,
  org_id,
  input,
  setInput,
  setFiles,
  profilePicture,
  username
) => {
  const newFileList = file.map((file) => ({
    name: file.name,
    url: file.key,
    type: file.type,
  }));
  let RoomId;
  if (recieverId > senderId) {
    RoomId = `${senderId}${recieverId}`;
  } else {
    RoomId = `${recieverId}${senderId}`;
  }

  const userMessagesRef = ref(db, `${org_id}/chat_rooms/${RoomId}/messages`);
  const newMessageRef = push(userMessagesRef);
  // Set the new message in the "messages" subcollection
  set(newMessageRef, {
    userid: senderId,
    username: username,
    text: input.message,
    documents: newFileList,
    profile_pic: profilePicture,
    seenBySender: false,
    seenByReceiver: false,
    timestamp: serverTimestamp(),
  });

  // Update the last message in the chat room
  const chatRoomRef = ref(db, `${org_id}/chat_rooms/${RoomId}`);
  update(chatRoomRef, {
    senderID: senderId,
    recieverID: recieverId,
    lastMessage: input.message,
    timestamp: serverTimestamp(),
    seenByReciever: false,
  });

  setInput({
    message: "",
    username: username,
  });
  setFiles([]);
};

export const fetchUsersChatP2P = async (
  senderId,
  recieverId,
  org_id,
  setUserMessages,
  setLoadingUserChat,
  setSeenupdate
) => {
  setSeenupdate(true);
  setLoadingUserChat(true);
  try {
    let RoomId;
    if (recieverId > senderId) {
      RoomId = `${senderId}${recieverId}`;
    } else {
      RoomId = `${recieverId}${senderId}`;
    }
    // Create a query to fetch all messages ordered by timestamp
    const messagesRef = ref(db, `${org_id}/chat_rooms/${RoomId}/`); // Assuming your messages are under the "users" node
    const messagesQuery = query(messagesRef, orderByChild("timestamp"));

    const snapshot = await get(messagesQuery);

    if (snapshot.exists()) {
      const allMessages = [];
      snapshot.forEach((userSnapshot) => {
        const userId = userSnapshot.key;
        const userMessages = userSnapshot.val() || {};

        Object.keys(userMessages).forEach((messageKey) => {
          const message = userMessages[messageKey];
          if (message.text || message.username) {
            allMessages.push({ messageId: messageKey, userId, ...message });
          }
        });
      });
      // Sort all messages by timestamp (oldest to newest)
      allMessages.sort((a, b) => a.timestamp - b.timestamp);
      setUserMessages(allMessages);
      setSeenupdate(false);
    } else {
      setUserMessages([]);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoadingUserChat(false);
  }
};

export const listenForNewMessagesP2P = (
  senderId,
  recieverId,
  org_id,
  setUserMessages
) => {
  let RoomId;
  if (recieverId > senderId) {
    RoomId = `${senderId}${recieverId}`;
  } else {
    RoomId = `${recieverId}${senderId}`;
  }
  const messagesRef = ref(db, `${org_id}/chat_rooms/${RoomId}/`);
  // Use the onChildAdded method to listen for new messages
  onChildAdded(messagesRef, (userSnapshot) => {
    const userId = userSnapshot.key;
    const userMessagesRef = ref(db, `${org_id}/chat_rooms/${RoomId}/${userId}`);

    // Now, listen to the "messages" node for this user to get updates for their messages
    onChildAdded(userMessagesRef, (messageSnapshot) => {
      const newMessage = messageSnapshot.val();
      const messageId = messageSnapshot.key;
      // Handle the new message (e.g., add it to your messages state)
      setUserMessages((prevMessages) => [
        ...prevMessages,
        { messageId: messageId, ...newMessage },
      ]);
    });
  });
};

export const extractAllChatRooms = async (org_id, setUserChat, userid) => {
  const chatRoomsRef = ref(db, `${org_id}/chat_rooms/`); // Array to store chat data for all rooms

  // Listen for changes to the chat_rooms reference
  onValue(chatRoomsRef, (snapshot) => {
    if (snapshot.exists()) {
      const chatRoomsData = snapshot.val();
      const userChatRooms = [];

      // Iterate through all room IDs and check if the user is a part
      for (const roomId in chatRoomsData) {
        const chatData = chatRoomsData[roomId];

        const senderId = chatData.senderID;
        const receiverId = chatData.recieverID;

        const timestamp = chatData.timestamp;

        if (senderId === userid || receiverId === userid) {
          // User is a part of this room as sender or receiver
          userChatRooms.push({
            roomId: roomId,
            lastMessage: chatData.lastMessage,
            senderId: senderId,
            seenByReceiver: chatData.seenByReciever,
            receiverId: receiverId,
            timestamp: timestamp,
          });
        }
        userChatRooms.sort((a, b) => b.timestamp - a.timestamp);
      }
      console.log(userChatRooms);
      setUserChat(userChatRooms);
      return userChatRooms;
    } else {
      console.log("No chat rooms found.");
    }
  });
};

export const updateSeenStatusOfRoom = (
  currentUser,
  senderid,
  org_id,
  chattingwith
) => {
  console.log(currentUser, senderid, org_id, chattingwith);
  let RoomId;
  if (chattingwith > currentUser) {
    RoomId = `${currentUser}${chattingwith}`;
  } else {
    RoomId = `${chattingwith}${currentUser}`;
  }
  const messageRef = ref(db, `${org_id}/chat_rooms/${RoomId}/`);
  // Determine which field to update based on the user's role (sender or receiver)
  const seenField = "seenByReciever";
  if (currentUser !== senderid)
    update(messageRef, {
      [seenField]: true,
    });
};

export const updateSeenStatus = (
  messageId,
  currentUser,
  senderid,
  org_id,
  chattingwith
) => {
  let RoomId;
  if (chattingwith > currentUser) {
    RoomId = `${currentUser}${chattingwith}`;
  } else {
    RoomId = `${chattingwith}${currentUser}`;
  }
  const messageRef = ref(
    db,
    `${org_id}/chat_rooms/${RoomId}/messages/${messageId}`
  );
  // Determine which field to update based on the user's role (sender or receiver)
  const seenField =
    currentUser === senderid ? "seenBySender" : "seenByReceiver";

  // Update the "seen" status for the message
  update(messageRef, {
    [seenField]: true,
  });
};

export const listenToMessages = (
  senderId,
  recieverId,
  org_id,
  setUserMessages,
  setLoadingUserChat,
  setSeenupdate
) => {
  let RoomId;
  setSeenupdate(true);
  if (recieverId > senderId) {
    RoomId = `${senderId}${recieverId}`;
  } else {
    RoomId = `${recieverId}${senderId}`;
  }
  const messagesRef = ref(db, `${org_id}/chat_rooms/${RoomId}/`);

  onValue(messagesRef, (snapshot) => {
    if (snapshot.exists()) {
      // Call the fetchUsersChatP2P function to fetch the updated data
      fetchUsersChatP2P(
        senderId,
        recieverId,
        org_id,
        setUserMessages,
        setLoadingUserChat,
        setSeenupdate
      );
    }
  });
};

//fort date formate ********************
export const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return "Timestamp not available";
  }

  const date = new Date(timestamp);
  const now = new Date();
  const isToday =
    date.getDate() === now.getDate() && date.getMonth() === now.getMonth();

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format
  };

  if (isToday) {
    const formattedTime = date.toLocaleTimeString("en-US", options);
    const [time, period] = formattedTime.split(" ");
    return `${time} ${period.toUpperCase()}`;
  } else {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      ...options,
      hour12: true,
    });
    const [time, period] = formattedTime.split(" ");
    return `${day} ${month}, ${time} ${period.toUpperCase()}`;
  }
};

//*********** NOTIFICATION **************** */

export const setNotificationdata = async (
  userId = [],
  org_id = "",
  username = "",
  postId = "",
  type = "",
  createdby = ""
) => {
  // console.log(userId,
  //   org_id,
  //   username,
  //   postId,
  //   type,
  //   createdby, "try11" )

  try {
    const userMessagesRef = ref(db, `Notification/${org_id}/`);
    const newMessageRef = push(userMessagesRef);

    await set(newMessageRef, {
      username: username,
      userId: userId,
      username: username,
      userId: userId,
      postId: postId,
      type: type,
      timestamp: serverTimestamp(),
      seen: [userId],
      post_created_by_id: createdby, // storing the post creator's ID
      post_created_by_id: createdby, // storing the post creator's ID
    });
  } catch (error) {
    console.error("Failed to set notification data:", error);
  }
};

export const setFollowNotification = async (
  followerId,
  followedUserId,
  org_id,
  followerName
) => {
  try {
    const followNotificationsRef = ref(db, `Notification/${org_id}/`);

    const newNotificationRef = push(followNotificationsRef);

    await set(newNotificationRef, {
      userId: followedUserId,
      type: "follow",
      followerId: followerId,
      followerName: followerName,
      timestamp: serverTimestamp(),
      seen: [],
    });
  } catch (error) {
    console.error("Failed to set follow notification data:", error);
  }
};

export const listenToFeed = (org_id) => {
  const messagesRef = ref(db, `Notification/${org_id}/`);
  onChildAdded(messagesRef, (userSnapshot) => {
    const userId = userSnapshot.key;
    // console.log(userSnapshot.val(), "snap11")
  });
};

export const fetchUsersNotificationP2P = async (org_id, setAllNotification) => {
  try {
    // Create a query to fetch all messages ordered by timestamp
    const messagesRef = ref(db, `Notification/${org_id}/`);
    const messagesQuery = query(messagesRef);
    onChildAdded(messagesRef, async (userSnapshot) => {
      const snapshot = await get(messagesQuery);

      if (snapshot.exists()) {
        const allMessages = [];

        snapshot.forEach((notificationSnapshot) => {
          // Extract Firebase ID from the snapshot key
          const notificationId = notificationSnapshot.key;

          // Get the notification data
          const notificationData = notificationSnapshot.val() || {};

          // Include the Firebase ID in the notification data
          notificationData.id = notificationId;

          // Push notification data with Firebase ID to the array
          allMessages.push(notificationData);
        });

        // Sort notifications by timestamp (newest first)
        allMessages.sort((a, b) => b.timestamp - a.timestamp);

        // Filter notifications to ensure all relevant types are included
        const filteredNotifications = allMessages.filter((notification) => {
          return (
            notification.type === "quiz" ||
            notification.type === "courses" ||
            notification.type === "learning-path" ||
            notification.type === "feedback" ||
            notification.type === "comment" ||
            notification.type === "like" ||
            notification.type === "unlike"
          );
        });
        const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

        allMessages.forEach((notification) => {
          const currentTime = new Date().getTime(); // Get current time in milliseconds
          const notificationTime = new Date(notification.timestamp).getTime(); // Get notification time in milliseconds

          // Check if the notification is older than 30 days
          if (currentTime - notificationTime > THIRTY_DAYS_IN_MS) {
            const notificationRef = ref(
              db,
              `Notification/${org_id}/${notification.id}`
            );
            remove(notificationRef); // Delete the notification

            // Remove from allMessages array
            const index = allMessages.indexOf(notification);
            if (index > -1) {
              allMessages.splice(index, 1);
            }
          }
        });
        // Pass the array of notifications to the provided callback function
        setAllNotification(allMessages, filteredNotifications);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSeenNotifyStatus = (
  notificationId,
  userId,
  org_id,
  setAllNotification
) => {
  const messageRef = ref(db, `Notification/${org_id}/${notificationId.id}`);
  let array = [];
  if (notificationId?.seen?.length > 0) {
    if (notificationId.seen.includes(userId)) {
      array = notificationId.seen;
    } else {
      array = [...notificationId.seen, userId]; //Using the notification Id comparing the length
    }
  } else {
    array.push(userId);
  }
  // Update the "seen" status for the message
  update(messageRef, {
    seen: array,
  });
  fetchUsersNotificationP2P(org_id, setAllNotification, userId);
};

//for date formate for streak continuation from formatTimestamp ********************
function isNextDay(lastDate) {
  const currentDate = new Date();
  lastDate = new Date(lastDate);
  console.log(lastDate);
  // Check if the last recorded date is before yesterday
  if (
    currentDate.getDate() === lastDate.getDate() + 1 &&
    currentDate.getMonth() === lastDate.getMonth() &&
    currentDate.getFullYear() === lastDate.getFullYear()
  ) {
    // If the date is correct, also check the timespan
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const timespanHour = 0; // Adjust as needed
    const timespanMinute = 0; // Adjust as needed

    const timespanPassed =
      currentHour > timespanHour ||
      (currentHour === timespanHour && currentMinute >= timespanMinute);

    console.log(timespanPassed);
    return timespanPassed;
  }
  console.log("timespanPassed");
  return false;
}

// Function to check if the gap between the last recorded date and the current date is greater than one day
function isGapTooLarge(lastDate, maxGapInMilliseconds) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  lastDate = new Date(lastDate);
  lastDate.setHours(0, 0, 0, 0);

  const gapInMilliseconds = currentDate.getTime() - lastDate.getTime();

  return gapInMilliseconds > maxGapInMilliseconds;
}

//streaks api

export const updateStreak = async (userid, org_id) => {
  const userStreakRef = ref(db, `${org_id}/user_streak/${userid}`);

  try {
    const userStreakSnapshot = await get(userStreakRef);
    const userStreakData = userStreakSnapshot.val();
    const startDate =
      new Date(userStreakData?.start_date)?.setHours(0, 0, 0, 0) ?? 0;
    const currentDate = new Date().setHours(0, 0, 0, 0);

    if (startDate === currentDate) {
      return userStreakData;
    }
    let currentStreak = 1;
    let lastStreakValue;

    if (userStreakSnapshot.exists()) {
      const streakRecord = Object.values(userStreakSnapshot.val());
      const maxGap = 2 * 24 * 60 * 60 * 1000;

      if (isGapTooLarge(startDate, maxGap)) {
        currentStreak = 1;
        lastStreakValue = userStreakData?.current_streak;
        update(userStreakRef, {
          current_streak: currentStreak,
          last_streak: userStreakData?.current_streak,
          start_date: serverTimestamp(),
        });
      } else if (isNextDay(startDate)) {
        currentStreak = userStreakData?.current_streak + 1;
        lastStreakValue = streakRecord?.current_streak
          ? streakRecord?.current_streak
          : 0;
        update(userStreakRef, {
          current_streak: currentStreak,
          last_streak: lastStreakValue,
          start_date: serverTimestamp(),
        });
      } else {
        currentStreak = streakRecord?.current_streak ?? 1;
      }
    } else {
      await set(userStreakRef, {
        start_date: serverTimestamp(),
        current_streak: currentStreak,
        last_streak: currentStreak,
      });
    }

    return { current_streak: currentStreak, last_streak: lastStreakValue };
  } catch (err) {
    console.error(err);
    // Handle error, e.g., display an error message to the user
  }
};

//follow-unfollow--
// Follow a user
export const followUser = async (currentUserId, targetUserId) => {
  const db = getDatabase();

  // Add the follow relationship in your database
  await set(ref(db, `follows/${currentUserId}/${targetUserId}`), true);

  // Create a notification for the target user only
  const notificationRef = push(ref(db, `notifications/${targetUserId}`));
  await set(notificationRef, {
    type: "follow",
    followerId: currentUserId,
    message: `User ${currentUserId} has started following you.`,
    timestamp: Date.now(),
    isRead: false,
  });
};

// Unfollow a user
export const unfollowUser = async (currentUserId, targetUserId) => {
  try {
    const userDoc = doc(db, "users", currentUserId, "following", targetUserId);
    await deleteDoc(userDoc);

    // Remove follow notification
    const notificationDoc = doc(
      db,
      "notifications",
      targetUserId,
      "followNotifications",
      currentUserId
    );
    await deleteDoc(notificationDoc);
  } catch (error) {
    console.error("Error in unfollowUser:", error);
    throw new Error("Error unfollowing the user");
  }
};

// Check if the user is following
export const isUserFollowing = async (currentUserId, targetUserId) => {
  try {
    const userDoc = doc(db, "users", currentUserId, "following", targetUserId);
    const docSnap = await getDoc(userDoc);
    return docSnap.exists();
  } catch (error) {
    console.error("Error in isUserFollowing:", error);
    throw new Error("Error checking follow status");
  }
};

// Count followed users
export const countFollowedUsers = async (userId) => {
  try {
    const followedUsersRef = collection(db, `users/${userId}/followedUsers`);

    const followedUsersSnapshot = await getDocs(followedUsersRef);

    return followedUsersSnapshot.size;
  } catch (error) {
    console.error("Error in countFollowedUsers:", error);
    throw new Error("Error counting followed users");
  }
};

// Action Type
export const SET_FOLLOWED_USERS_COUNT = "SET_FOLLOWED_USERS_COUNT";

// Action Creator
export const setFollowedUsersCount = (count) => ({
  type: SET_FOLLOWED_USERS_COUNT,
  payload: count,
});

export const fetchMembersData = async (userId) => {
  const query = `
    SELECT users.id, users.username, users.role, departments.name AS department
    FROM follows
    JOIN users ON follows.followedUserId = users.id
    LEFT JOIN departments ON users.departmentId = departments.id
    WHERE follows.userId = ?
  `;

  const [members] = await db.query(query, [userId]);
  return members;
};
