import {createSlice} from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";

const groupSlice = createSlice({
  name:"group",
  initialState,
  reducers
});

export const groupActions = groupSlice.actions;

export default groupSlice.reducer;