import { message } from "antd";

//icons
import { ReactComponent as CheckIcon } from "../images/CheckIcon.svg";
import { ReactComponent as CloseIcon } from "../images/CloseIcon.svg";

//this is for testing purpose
const MessageComponent = ({
  apiStatus = 500,
  content = "",
  type = "destroy",
}) => {
  if (type === "error") {
    if (apiStatus === 500) {
      message.error({
        content:
          "Hmm.. that shouldn't happen. Please connect at dm@kognics.com",
        className: "error-msg",
        icon: <CloseIcon fill="#FFF" width="15px" />,
      });
    } else {
      message.error({
        content: content,
        className: "error-msg",
        icon: <CloseIcon fill="#FFF" width="15px" />,
      });
    }
  } else if (type === "success") {
    message.success({
      content: content,
      className: "success-msg",
      icon: <CheckIcon fill="#FFF" width="15px" />,
    });
  } else if (type === "info") {
    message.info({
      content: content,
    });
  } else if (type === "loading") {
    message.loading("Please wait...", 0);
  } else if (type === "destroy") {
    message.destroy();
  } else if (type === "fix") {
    message.error({
      content: content,
      className: "error-msg",
      icon: <CloseIcon fill="#FFF" width="15px" />,
    });
  }
};

export default MessageComponent;
