export const reducers = {
  setActiveCourse(state, action) {
    state.activeCourse = action.payload;
  },
  setAssessmentHeaderView(state, action) {
    state.headerView = action.payload;
  },
  setCourseAssessment(state, action) {
    state.courseAssessment = action.payload;
  },
  setCourseAssessmentFetching(state, action) {
    state.courseAssessmentFetching = action.payload;
  },
  setUsersList(state, action) {
    state.usersList = action.payload;
  },
  setCurrentUser(state, action) {
    state.currentUser = action.payload;
  },
  setUserResponseLoading(state, action) {
    state.userResponseLoading = action.payload;
  },
  setUserResponse(state, action) {
    state.userResponse = action.payload;
  },
  setResourceid(state, action) {
    state.resourceid = action.payload;
  },
  setResourceType(state, action) {
    state.resourceType = action.payload;
  },
  setAllQuestionsLoading(state, action) {
    state.allQuestionsLoading = action.payload;
  },
  setAssessmentAllQuestions(state, action) {
    state.assessmentAllQuestions = action.payload;
  },
  setCurrentResourceKey(state, action) {
    state.currentResourceKey = action.payload;
  },
  setAssessmentResourceDropdown(state, action) {
    state.assessmentResourceDropdown = action.payload;
  },
  resetAssessmentStore(state, action) {
    state.activeCourse = null;
    state.headerView = null;
    state.courseAssessmentFetching = true;
    state.courseAssessment = null;
    state.usersList = [];
    state.currentUser = null;
    state.currentUserResponse = [];
    state.userResponseLoading = true;
    state.allQuestionsLoading = true;
    state.assessmentAllQuestions = [];
    state.currentResourceKey = null;
    state.resourceid = null;
    state.resourceType = null;
    state.assessmentResourceDropdown = [];
  },
};
