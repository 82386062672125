import { configureStore } from "@reduxjs/toolkit";

//slices
import authReducer from "./auth";
import coursesReducer from "./courses";
import assetsReducer from "./assets";
import coursePlayerReducer from "./coursePlayer";
import assessmentReducer from "./assessment";
import createCourseReducer from "./NewCreateCourse";
import SettingsReducer from "./settings";
import quizReducer from "./quiz";
import feedbackReducer from "./feedback";
import learningPathReducer from "./learningPath";
import createLearningPathReducer from "./createLearningpath";
import productHubReducer from "./product-hub";
import group from "./group";
import Education from "./Education";

const store = configureStore({
  reducer: {
    auth: authReducer,
    courses: coursesReducer,
    assets: assetsReducer,
    coursePlayer: coursePlayerReducer,
    learningPath: learningPathReducer,
    createLearningPath: createLearningPathReducer,
    assessment: assessmentReducer,
    settings: SettingsReducer,
    createCourse: createCourseReducer,
    quiz: quizReducer,
    feedback: feedbackReducer,
    productHub: productHubReducer,
    group: group,
    Education:Education,
  },
});

export default store;
