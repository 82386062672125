import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducer";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers,
});

export const feedbackActions = feedbackSlice.actions;

export default feedbackSlice.reducer;
