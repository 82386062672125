import { initialState } from "./initialState";

export const reducers = {
  setCourseName(state, action) {
    state.courseName = action.payload;
  },
  setLessons(state, action) {
    state.lessons = action.payload;
  },
  setActiveLesson(state, action) {
    state.activeLesson = action.payload;
  },
  setActiveAsset(state, action) {
    state.activeAsset = action.payload;
  },
  setActiveLessonData(state, action) {
    state.activeLessonData = action.payload;
  },
  setActiveAssets(state, action) {
    state.activeAssets = action.payload;
  },
  setCurrentCourseId(state, action) {
    state.currentCourseId = action.payload;
  },

  setCourseVersion(state, action) {
    state.courseVersion = action.payload;
  },

  setCurrentCourse(state, action) {
    state.currentCourse = action.payload;
  },
  setAlreadyAddedAssets(state, action) {
    //Id of assets
    if (action.payload.delete) {
      state.alreadyAddedAssetsIds = state.alreadyAddedAssetsIds.filter(
        (asset) =>
          asset !== action.payload.assetId || asset !== action.payload.topicId
      );
    } else {
      state.alreadyAddedAssetsIds = action.payload;
    }
  },
  setActiveView(state, action) {
    state.activeView = action.payload;
  },
  setActiveTopic(state, action) {
    state.activeTopic = action.payload;
  },
  resetTopic(state) {
    state.activeTopic = {};
  },
  setAddLessonTab(state, action) {
    state.addLessonTab = action.payload;
  },

  setDefaultValue(state) {
    Object.assign(state, initialState);
  },

  setGlobal(state, action) {
    state.global = action.payload;
  },
  setTempSelectedAssets(state, action) {
    const updatedAssets = action.payload.map((asset, index) => {
      return { ...asset, assetid: asset.id, ordering: index + 1 };
    });
    state.tempSelectedAssets = updatedAssets;
  },
  setCreateNewQuizInsideCourse(state, action) {
    state.createNewQuizInsideCourse = action.payload;
  },
  setUploadScromFileInCourse(state, action) {
    state.uploadScromFileInCourse = action.payload;
  },
  setAddTopic(state, action) {
    state.addTopic = action.payload;
  },
  setAddTopicHtmlContent(state, action) {
    state.addTopicHtmlContent = action.payload;
  },
  setNotesHtmlContent(state, action){
    state.notesHtmlContent=action.payload;
  },
  setAddedQuizIds(state,action){
    state.addedQuizIds = action.payload;
  },

  setSelectedFeedback(state, action) {
    state.selectedFeedback = action.payload;
  },

  setDeletedQuizIds(state, action) {
    state.deletedQuizIds = action.payload;
  },

  setAddedQuiz(state, action) {
    state.addedQuiz = action.payload;
  },
};
