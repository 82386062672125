import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import Email from './emailEditor';
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { settingsActions } from '../../../../../store/settings';
import '../emailTemplates.scss'

const  CreateTemplate=()=>{
  const dispatch = useDispatch();

  const Navigate=useNavigate();

    const {addTemplate, 
        setAddTemplate,
        setAddNewTempModal,
        CreateTemplateScreen, 
        editFlag, setEditFlag,
        SetCreateTemplateScreen,
        setSaveTemplate,
        templateName, setTemplateName,
        templateSubject, setTemplateSubject,
        selectedOption, setSelectedOption,
        editTemplate, templates,
        defaultTemplate, setDefaultTemplate
        

    } =useSelector((state)=>state.settings)
    const saveTemplate=useSelector((state)=>state.settings.saveTemplate)

    const [isSaved, setSaved]=useState(false);
    

      useEffect(()=>{

        if(templateName==='' || templateSubject==='' || selectedOption===''){
          Navigate('/settings/email-templates')
        }

      },[])
    
      const handleCreateTemplateScreenCancel = () => {
        Navigate('/settings/email-templates')
        dispatch(settingsActions.setAddNewTempModal(false))
      };

      const handleSaveTemplate = async() => {
         dispatch(settingsActions.setSaveTemplate(true));
      };
      
    


    return (
        <div className="create-Template">


        <div className='create-temp-modal-heading'>
            <h3>
              Create Template
 
            </h3>
           

              <div className='desicion'>
        
        <div  style={{ cursor: 'pointer' }} onClick={handleCreateTemplateScreenCancel}>
          Cancel
        </div>
        <div style={{ padding:"0px 13px" }}>
          <p style={{margin:"0px"}}>or</p>
        </div>
        <Button className='create-btn' onClick={handleSaveTemplate} >{editFlag ? 'Update' : 'Create'}</Button>
        {/* onClick={handleCreateTemplate} */}
          </div>
      </div>
        <div className=''>
        <Email />

        </div>
    
      {/* </Modal> */}
        </div>
    )

}

export default CreateTemplate