export const initialState = {
  feedbackModalVisible: false,
  activeFeedback: {},
  loading: true,
  publishedFeedback: [],
  draftFeedback: [],
  search: "",
  sortType: "id",
  sortOrder: "desc",
  assetTypes: [],
  uploadDate: "all",
  customDate: [],
  currentTab: "published",
  feedbackId: "",
  userName: "",
  courseName: "",
  learningPathName: "",
  currentFeedbackType: "",
};
