import { quizConstants } from "../../utils/contants";


export const reducers = {
  startQuiz(state, action) {
    const { started_at, take_id, questions, timer } = action.payload;
    state.started = true;
    state.started_at = started_at;
    state.take_id = take_id;
    state.questions = questions;
    state.timer = timer;
    state.result = {};
  },
  manualReload(state, action) {
    state.manualReloading = !state.manualReloading;
  },
  setActiveQuestionNumber(state, action) {
    switch (action.payload) {
      case quizConstants.PREVIOUS: {
        if (state.activeQuestionNumber !== 0) state.activeQuestionNumber--;
        break;
      }
      case quizConstants.NEXT: {
        state.activeQuestionNumber++;
        break;
      }
      default:
        return;
    }
  },
  setView(state, action) {
    state.activeView = action.payload;
  },
  showQuizResult(state, action) {
    state.result = action.payload;
    state.view = "result";
  },
  setResult(state, action) {
    state.result = action.payload;
  },
  resetQuiz(state, action) {
    state.activeQuestionNumber = 0;
    state.score = 0;
    state.result = {};
    state.disableSubmitButton = false;
    state.activeView = quizConstants.VIEW_INSTRUCTION;
    state.quizResponse = [];
  },
  setQuizDetails(state, action) {
    state.quizInfo = action.payload;
  },
  setQuizTakeInfoLoading(state, action) {
    state.quizTakeInfoLoading = action.payload;
  },
  setStartQuizLoading(state, action) {
    state.startQuizLoading = action.payload;
  },
  setFinishQuizLoading(state, action) {
    state.finishQuizLoading = action.payload;
  },
  setQuizPlayerModal(state, action) {
    state.quizPlayerModal = action.payload;
  },
  setQuizResponse(state, action) {
    state.quizResponse = action.payload;
  },
  setSubmitButtonDisabled(state, action) {
    state.disableSubmitButton = action.payload;
  },
  setQuizAssignModalVisible(state, action) {
    state.quizAssignModalVisible = action.payload;
  },
  setQuizId(state, action) {
    state.quizId = action.payload;
  },
  setQuizName(state, action) {
    state.quizName = action.payload;
  },
  setQuizReportModal(state, action) {
    state.quizReport_Modal = action.payload;
  },

  setChatQuizBox(state, action){
    state.ChatQuizBox=action.payload
  }
};
