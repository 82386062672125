import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./productHubInitialState";
import { reducers } from "./productHubReducers";

const productHubSlice = createSlice({
  name: "productHub",
  initialState,
  reducers,
});

export const productHubActions = productHubSlice.actions;

export default productHubSlice.reducer;
