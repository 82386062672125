export const initialState = {
  createAssetName: "",
  recordModalVisible: false,
  publishedAssets: [],
  draftAssets: [],
  loading: true,
  search: "",
  sortType: "id",
  sortOrder: "desc",
  assetTypes: [],
  uploadDate: "all",
  customDate: [],
  currentTab: "published",
  filterType: "",
  filter: false,
  isLive: false,
  isRandomize: false,
  isReportVisible: false,
  dateTimeValuequiz: {
    startingDate: null,
    closingDate: null,
    startingTime: null,
    closingTime: null,
  },
};
