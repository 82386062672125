export const initialState = {
  isDrawerVisible: true,

  //assignedToMe cards
  CourseCards: [
    {
      TrainingsCategory: "Assigned To me",
      CourseName: "Digital Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: " Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: "Digital Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: " Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: "Digital Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: " Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },
    {
      TrainingsCategory: "Assigned To me",
      CourseName: " Marketing Trends",
      Createdby: "Tony Stark",
      Description:
        "Lorem nwedjkenw wjndjkwednkwe dnewjkdnwejk wjqbdjqbdjnd qwjdnjqwdnjkwed jwdjjnqdijnwe hniuewdniuwejndfuiwe ",
      CourseDetails: "Details",
      Duration: "30 days",
      CourseCompleted: 20,
      CourseThumbnail: "",
      CourseStatus: "Ongoing Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["abc Skills", "Presentation Skills", "creative Skills"],
    },

    {
      TrainingsCategory: "Assigned To me",
      CourseName: "New Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 60,
      CourseThumbnail: "",
      CourseStatus: "Pending Courses",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Speaking Skills", "Presentation Skills"],
    },

    {
      TrainingsCategory: "Assigned To me",
      CourseName: "Old Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 80,
      CourseThumbnail: "",
      CourseStatus: "Pending Courses",
      Lessons: [
        {
          id: "1",
          name: "Lesson 1",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Communication Skills", "Presentation Skills"],
    },
    {
      TrainingsCategory: "Explore Courses",
      CourseName: "New Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 30,
      CourseThumbnail: "",
      CourseStatus: "Public course",
      Lessons: [
        {
          id: "1",
          videos: [
            { video1: "abc", Title: "Getting started", duration: "20 mins" },
            { video2: "abc", Title: "Next Step", duration: "30 mins" },
            { video3: "abc", Title: "video 3 title", duration: "25 mins" },
          ],
          Articles: [{ article1: "abc", duration: "10 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "49 mins" },
          ],
          Articles: [
            { article1: "abc", Title: "article title", duration: "20 mins" },
          ],
          Quizes: [
            {
              name: "quiz 1",
              duration: "4 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [
            { video1: "abc", duration: "4 mins" },
            { video2: "abc", duration: "43 mins" },
          ],
          Articles: [{ article1: "abc", duration: "12 mins" }],
          Quizes: [
            {
              name: "quiz 1",
              duration: "6 mins",
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Speaking Skills", "Presentation Skills"],
    },
    {
      TrainingsCategory: "Explore Courses",
      CourseName: "New Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 50,
      CourseThumbnail: "",
      CourseStatus: "Private course",
      Lessons: [
        {
          id: "1",
          name: "Lesson 1",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Speaking Skills", "Presentation Skills"],
    },
    {
      TrainingsCategory: "Explore Courses",
      CourseName: "New Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 50,
      CourseThumbnail: "",
      CourseStatus: "Library",
      Lessons: [
        {
          id: "1",
          name: "Lesson 1",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Speaking Skills", "Presentation Skills"],
    },
    {
      TrainingsCategory: "Created by me",
      CourseName: "New Trends",
      Createdby: "Chris Evans",
      Description: "abdb wjdnjewnd ewndjwend",
      CourseDetails: " detials",
      Duration: "20 days",
      CourseCompleted: 50,
      CourseThumbnail: "",
      CourseStatus: "Library",
      Lessons: [
        {
          id: "1",
          name: "Lesson 1",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "2",
          name: "Lesson 2",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
        {
          id: "3",
          name: "Lesson 3",
          videos: [{ video1: "abc" }, { video2: "abc" }],
          Articles: [{ article1: "abc" }],
          Quizes: [
            {
              quiz1: {
                questions: { ques1: "ans" },
              },
            },
          ],
        },
      ],
      Skills: ["Speaking Skills", "Presentation Skills"],
    },
  ],
  //explorSectionCards
};
