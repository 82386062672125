import { playerConstants } from "../../utils/contants";

export const initialState = {
  coursePlayerModal: {}, // type:course/quizResult, status: true/false,points for course point

  activePanel: undefined,
  activeLesson: undefined,
  activeAsset: undefined,
  cachedAssetDetails: {},
  courseInfo: {},
  courseResult: {},
  lessonInfo: [],
  courseInfoFetching: true,
  completedAssets: [],
  allAssets: [],
  forceSequence: [],
  activeView: playerConstants.VIEW_CONTENT,
  courseProgress:0,
  lessonsObject: {},
  courseCompletionAllowed: false,
  courseFeedbackPopup: false,
  courseSubmitted: false,
  isCourseSubmiting: false,
  hasDescriptiveQuestion: false, // this will send courses for underReview
};
