import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./learningPathInitialState";
import { reducers } from "./learningPathReducers";

const learningPathSlice = createSlice({
  name: "learningPath",
  initialState,
  reducers,
});

export const learningPathActions = learningPathSlice.actions;

export default learningPathSlice.reducer;
