import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducer";

const createCourseSlice = createSlice({
  name: "createCourse",
  initialState,
  reducers,
});

export const createCourseActions = createCourseSlice.actions;

export default createCourseSlice.reducer;