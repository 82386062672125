import {createSlice} from "@reduxjs/toolkit"
import { initialState } from "./initialState";
import { reducers } from "./reducer";

const EducationSlice= createSlice({
    name:"createEducation",
    initialState,
    reducers
})

export const EducationActions=EducationSlice.actions;
export default EducationSlice.reducer