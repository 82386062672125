export const initialState = {
  data: [],
  activeCourses: undefined,
  activeAssets: [],
  currentLearningPathId: undefined,
  currentLearningPath: {},
  alreadyAddedAssets: [],
  alreadyAddedCourses: [],
  activeTopic: {},
  setAddTopicHtmlContent: "",
  Topic: {},
};
