export const reducers = {
  setIsLoading(state, action) {
    state.isLoading = action.payload;
  },
  setGroupId(state, action) {
    state.groupId = action.payload;
  },
  setCreatedBy(state, action) {
    state.createdBy = action.payload;
  },
  setMentor(state, action) {
    state.mentor = action.payload;
  },
  setAdminMentorView(state, action) {
    state.adminMentorView = action.payload;
  },
  setIsAuthorize(state, action) {
    state.isAuthorize = action.payload;
  },
};
