import React, { useRef, useContext, useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import '../emailTemplates.scss';
import { v4 as uuidv4 } from 'uuid';
import {useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { settingsActions } from '../../../../../store/settings';
import { Spin } from 'antd';
import { message } from 'antd';

const Email = () => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const emailEditorRef = useRef(null);
  const prevTemplatesLength = useRef(0); 
  const [move , setMove]=useState(false); 
  const {

    selectedCategory,
    setAddTemplate,
    addTemplate,
    setEditFlag,
    editFlag,
    activeTemplate,
    editIndex,
    editTemplate,
    setEditIndex,
    setAddNewTempModal,
    templates,
    defaultTemplate, setDefaultTemplate,

    templateName, setTemplateName,
        templateSubject, setTemplateSubject,
        selectedOption, setSelectedOption,
        SetCreateTemplateScreen,
        setSaveTemplate,ChangeDefault,
  } = useSelector((state)=>state.settings)

  const [tempData, setTempdata]=useState(templates);

  useEffect(()=>{

    if(selectedCategory!==selectedOption){
      dispatch(settingsActions.setSelectedCategory(selectedOption));
    }


  },[])

  const updateTemplates = (updatedTemplates) => {
    const updatedTemplatesNew = [...updatedTemplates];

     const sortedTemplates = updatedTemplatesNew.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
    dispatch(settingsActions.setTemplates(sortedTemplates));
    // ChangeDefaultTemplate();
    //console.log("templates after sorting in email editor component", templates)
  };

  const saveTemplate=useSelector((state)=>state.settings.saveTemplate)

  const Navigate=useNavigate();

  const EditTemplate = (index, updatedTemplate) => {

    console.log("index", index);
    console.log("templates", templates)
    const updatedTemplates = [...templates];
    const templateToChangeIndex=updatedTemplates.findIndex(
      (template)=>template.id === index 
    );
    updatedTemplates[templateToChangeIndex]=updatedTemplate
    console.log("copied templates ", updatedTemplates)
    //console.log("before updation of partcular one templates ", updatedTemplates[templateToChangeIndex])
    //updatedTemplates.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
    //updatedTemplates[index] = updatedTemplate;
   // console.log("after updation of partcular one templates ", updatedTemplates[templateToChangeIndex])
   console.log("all updated", updatedTemplates)
   console.log("value of Change Default", ChangeDefault)
  

    if (ChangeDefault) {
      const templateIndexToChange = updatedTemplates.findIndex(
        (template) => template.selectedCategory === selectedCategory && template.defaultTemplate
      );

     // console.log("template to chnage inside edit case",updatedTemplates[templateIndexToChange] )

      if (templateIndexToChange !== -1) {
        updatedTemplates[templateIndexToChange] = {
          ...updatedTemplates[templateIndexToChange],
          defaultTemplate: false,
        };

        //console.log("changed the target", updatedTemplates[templateIndexToChange] )

        // Set defaultTemplate to true for the last template
        const EdittemplateToChangeIndex=updatedTemplates.findIndex(
          (template)=>template.id === index 
        );

        // const lastTemplateIndex = updatedTemplates.length - 1;
        updatedTemplates[EdittemplateToChangeIndex] = {
          ...updatedTemplates[EdittemplateToChangeIndex],
          defaultTemplate: true,
        };
       
        //updatedTemplates.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
       // console.log("templates new ", updatedTemplates)
        EditTemplateTwo(templateIndexToChange,EdittemplateToChangeIndex, updatedTemplates[templateIndexToChange]  ,updatedTemplates[EdittemplateToChangeIndex], updatedTemplates )
        dispatch(settingsActions.setChangeDefault(false));


      } else {
        dispatch(settingsActions.setTemplates(updatedTemplates));
      }
    }

    else{
      //updatedTemplates.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
      dispatch(settingsActions.setTemplates(updatedTemplates));
     

    }
  
  
    //const sortedTemplates = updatedTemplates.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
    
    dispatch(settingsActions.setEditFlag(false));
    dispatch(settingsActions.setEditIndex(null));
    dispatch(settingsActions.setAddNewTempModal(false));
  };

  const EditTemplateTwo = (index1, index2, Temp1, Temp2,updatedTemplates) => {
    //console.log("edit temp 2 function called");
  
    const UpdatedTemplates = [...updatedTemplates];


    UpdatedTemplates[index1] = Temp2;
    UpdatedTemplates[index2] = Temp1;
  
   
    // const lastTemplateIndex = updatedTemplates.length - 1;
    // updatedTemplates[lastTemplateIndex] = {
    //   ...updatedTemplates[lastTemplateIndex],
    //   defaultTemplate: true,
    // };
  
   
    UpdatedTemplates.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
  
    console.log("after swapping", UpdatedTemplates);
  
    dispatch(settingsActions.setTemplates(UpdatedTemplates));
  };
  

  
  const id=uuidv4();
  

  let templateObject={
    id,
    templateName,
    templateSubject,
    selectedOption,
    selectedCategory,
    defaultTemplate,
  };

  // const UpdateTemplates =()=>{

  // }

  useEffect(() => {
  
    if ((move === true && templates.length >= prevTemplatesLength.current)) {
      Navigate('/settings/email-templates');
      setMove(false);
      dispatch(settingsActions.setAddNewTempModal(false));
    }
  
  
    prevTemplatesLength.current = templates.length;
  }, [templates.length, templates]);

  
  

useEffect(() => {


  const exportHtml = () => {
    return new Promise((resolve) => {
      const unlayer = emailEditorRef.current?.editor;

      if (unlayer) {
        unlayer.exportHtml((data) => {
          const { design, html } = data || {};
  
          if (html) {
            

            if (!editFlag) {
             
              templateObject = { ...templateObject, html, design, id };
            }
            else{

              const updatedEditTemplate = {
                id: editTemplate.id,
                templateName: templateName,
                selectedOption: selectedOption,
                templateSubject: templateSubject,
                selectedCategory: selectedCategory,
                defaultTemplate: ChangeDefault ? defaultTemplate : editTemplate.defaultTemplate,
                html: html,
                design: design,

              };
              // eduted the tenplates
          
              EditTemplate(editIndex, updatedEditTemplate);

            }

            

            resolve();
          } else {
            
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  };

 


  const handleExport = async () => {

    await exportHtml(); //wait 
  
    if (!editFlag) {
      setTempdata(templates);
      const updatedTemplates = [...tempData, templateObject];
  
      let updatedTemplatesNew;
  
      if (ChangeDefault) {
        const templateIndexToChange = updatedTemplates.findIndex(
          (template) => template.selectedCategory === selectedCategory && template.defaultTemplate
        );
  
        if (templateIndexToChange !== -1) {
          updatedTemplatesNew = [...updatedTemplates];
  
          // Swap defaultTemplate property
          updatedTemplatesNew[templateIndexToChange] = {
            ...updatedTemplatesNew[templateIndexToChange],
            defaultTemplate: false,
          };
  
          // Set defaultTemplate to true for the last template
          const lastTemplateIndex = updatedTemplatesNew.length - 1;
          updatedTemplatesNew[lastTemplateIndex] = {
            ...updatedTemplatesNew[lastTemplateIndex],
            defaultTemplate: true,
          };
  
          // Sort the array based on the defaultTemplate property
          updatedTemplatesNew.sort((a, b) => (b.defaultTemplate ? 1 : 0) - (a.defaultTemplate ? 1 : 0));
          updateTemplates(updatedTemplatesNew)
          dispatch(settingsActions.setChangeDefault(false));
        } else {
          console.error("No default template found in the selected category.");
        }
      }
  
      updateTemplates(updatedTemplatesNew || updatedTemplates);
      
    } 
  };

  if (saveTemplate==true) {
    handleExport()

    dispatch(settingsActions.setSaveTemplate(false));
    dispatch(settingsActions.setEditFlag(false))
   // console.log("process executed", templates)
    dispatch(settingsActions.setDefaultTemplate(false));
   // console.log("closing and default value is,",defaultTemplate)
    setMove(true);
  }
 
}, [saveTemplate]);


  const onReady = () => {
   // setLoading(false);
    emailEditorRef.current.editor.loadDesign(editTemplate?.design);
    setLoading(false);
    
  };



  useEffect(() => {
    loading && message
    .open({
      type: 'loading',
      content: 'loading editor..',
      //duration: 2.5,
    })
    
  }, [loading]);
  

  return (
    <div>
      <EmailEditor
        ref={(ref) => {
          emailEditorRef.current = ref;
          editorRef.current = ref;
        }}
        className="Email-editor"
        onReady={editFlag ? onReady : undefined}
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '16px',
        }}
      
      />
    </div>
  );
  
};

export default Email;
