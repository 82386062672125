import {createSlice} from "@reduxjs/toolkit";
import { initialState } from "./coursesInitialState";
import { reducers } from "./coursesReducers";

const coursesSlice = createSlice({
  name:"courses",
  initialState,
  reducers
});

export const coursesActions = coursesSlice.actions;

export default coursesSlice.reducer;