import { ADD_LESSON_VIEW_ENUM, htmlWithTable2 } from "../../utils/contants";

export const initialState = {
  global: false,
  courseName: "",

  lessons: [],
  activeLesson: undefined,
  activeAsset: undefined,
  activeAssets: [],
  currentCourseId: undefined,
  currentCourse: {},
  addLessonTab: "add-lessons",
  activeView: ADD_LESSON_VIEW_ENUM.ADD_LESSON,
  alreadyAddedAssets: [],
  alreadyAddedAssetsIds: [],
  activeTopic: {},
  addTopic: false,
  tempSelectedAssets: [], // used while inserting asset from table
  createNewQuizInsideCourse: false,
  addTopicHtmlContent: "",
  notesHtmlContent:"",
  courseVersion: undefined,
  addedQuizIds: [],
  selectedFeedback: null,
  deletedQuizIds: [],
  addedQuiz: [],
  uploadScromFileInCourse: false,
};
